// Next
// Next
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import { NextPage } from 'next';
// next auth
import { SessionProvider, useSession } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
// components
import SnackbarProvider from '@/components/snackbar';
import I18PhraseLayout from '@/layouts/i18-phrase/i18PhraseLayout';
import { I18nContextProvider, useI18nContext } from '@/providers/I18nProvider/i18nProvider';
// providers
import SettingsProvider from '@/providers/SettingsProvider/SettingsProvider';
// common-frontend-components
import { ThemeProvider } from '@cdp/mui-ui-common';
// config
import { MuiX, api_mocking } from '@config';
// Dev Cycle
import { appWithDevCycle } from '@devcycle/nextjs-sdk/pages';
// fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// license
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/nextjs';
import i18next from '../i18.config';

LicenseInfo.setLicenseKey(MuiX.key);

if (api_mocking === 'enabled' && typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('../mocks/browser');
  worker.start();
}

type NextPageWithLayout = NextPage & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
}

const I18DynamicProvider = (props: MyAppProps) => {
  const { Component, pageProps } = props;
  const { data: session } = useSession();
  const { phraseEnabled } = useI18nContext();
  const getLayout = Component.getLayout ?? (page => page);

  const phraseInContext = new PhraseInContextEditorPostProcessor({
    phraseEnabled: phraseEnabled,
    projectId: process.env.NEXT_PUBLIC_PHRASE_PROJECT_ID ?? '',
    accountId: process.env.NEXT_PUBLIC_PHRASE_ACCOUNT_ID ?? '',
    useOldICE: false,
    prefix: '{{__',
    suffix: '__}}',
    autoLowercase: false,
  });

  useEffect(() => {
    const changeLanguage = async () => {
      await i18next.changeLanguage(session?.portalLang);
    };
    if (session?.portalLang) {
      changeLanguage();
    }
    i18next.use(phraseInContext);

    const sentryUser = {
      id: session?.user?.id,
      username: session?.user?.id,
      roles: session?.user?.roles,
      isCDPAdmin: session?.user?.isCDPAdmin,
      isImpersonation: session?.user?.isImpersonation,
      commLanguage: session?.user?.extension_CommunicationLanguage,
      portalLanguage: session?.user?.extension_PortalLanguage,
      organisationId: session?.user?.extension_OrganisationId,
      isSetOnBackend: false,
    };

    Sentry.setUser(sentryUser);
    Sentry.setContext('CDPUserClient', sentryUser);
  }, [
    session,
    phraseEnabled,
  ]);

  return <I18nextProvider i18n={i18next}>{getLayout(<Component {...pageProps} />)}</I18nextProvider>;
};

const MyApp = (props: MyAppProps) => {
  const { pageProps } = props;
  const { session } = pageProps;
  const oneTrustScriptID = process.env.NEXT_PUBLIC_ONE_TRUST_DATA_DOMAIN_SCRIPT_ID;
  const router = useRouter();

  function loadOneTrustScript() {
    if (!document.querySelector(`script[src*="cookielaw.org/scripttemplates/otSDKStub.js"]`)) {
      const script = document.createElement('script');
      script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
      script.type = 'text/javascript';
      script.dataset.domainScript = oneTrustScriptID;
      script.setAttribute('data-document-language', 'true');
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        if ((window as any).OneTrust) {
          (window as any).OneTrust.Init();
        }
      };
    }
  }

  useEffect(() => {
    if (session?.user && !!oneTrustScriptID) {
      loadOneTrustScript();
    }
  }, [
    session?.user,
  ]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (oneTrustScriptID) {
        loadOneTrustScript();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [
    router.events,
  ]);

  return (
    <SessionProvider session={session}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <I18nContextProvider>
                <I18PhraseLayout>
                  <I18DynamicProvider {...props} />
                </I18PhraseLayout>
              </I18nContextProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </SessionProvider>
  );
};

export default appWithDevCycle(appWithTranslation(MyApp));
